/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql, Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
// import HeaderAlt from "./headeralt"
import "./layout.scss"

const Layout = ({ children, button, buttonrelatie }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      {/*<HeaderAlt siteTitle={data.site.siteMetadata.title} />*/}
      <div
        style={{
          margin: `0 auto`,
          // maxWidth: 960,
          paddingTop: 0,
        }}
      >
        {/* <div
          className="training"
          style={{
            display: !button && "none",
          }}
        >
          <Link to={"/opleidingen"}>Zipperr opleidingen</Link>
        </div> */}
        {/* <div
          className="training challenge"
          style={{
            display: !buttonrelatie && "none",
          }}
        > */}
        {/* <Link to={'/relatie'}>Relatie challenge</Link> */}
        {/* </div> */}
        <main>{children}</main>
        {/*<footer style={{textAlign: 'center'}}>*/}
        {/*  © {new Date().getFullYear()}, Built by Oussama El-Hajoui*/}
        {/*</footer>*/}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  button: PropTypes.bool,
  buttonrelatie: PropTypes.bool,
}

Layout.defaultProps = {
  button: true,
  buttonrelatie: true,
}

export default Layout
