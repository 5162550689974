import { Link } from "gatsby"
// import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.scss"
// import Img from "gatsby-image"
import ReactResizeDetector from "react-resize-detector"

const Header = ({ siteTitle }) => {
  const navBar = React.createRef()
  const hamBurgerIcon = React.createRef()

  // const data = useStaticQuery(graphql`
  //   query {
  //     logoImg: file(relativePath: { eq: "zipperlogo.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 100) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  const onResize = (...d) => {
    if (d[0] > 767) {
      navBar.current.classList.remove("expanded")
    }
  }

  const openMenu = e => {
    if (navBar.current.classList.contains("expanded")) {
      navBar.current.classList.remove("expanded")
    } else {
      navBar.current.classList.add("expanded")
    }
  }

  return (
    <header>
      <nav className="navbar" ref={navBar}>
        <div className="navbar--inner">
          {/* <div className="navbar--left-container">
            <ul className="navbar--nav-list">
              <li>
                <Link to="/contact" activeClassName="active">
                  Contact
                </Link>
              </li>
            </ul>
          </div> */}
          <div className="navbar--right-container">
            <ul className="navbar--nav-list">
              <li>
                <Link to={"/"} id="homeLink" activeClassName={"active"}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/traject" activeClassName="active">
                  Traject
                </Link>
              </li>

              <li>
                <Link to="/coaching" activeClassName="active">
                  Coaching
                </Link>
              </li>
              <li>
                <Link to="/organisatieontwikkeling" activeClassName="active">
                  Organisatieontwikkeling
                </Link>
              </li>

              <li>
                <Link to="/teamontwikkeling" activeClassName="active">
                  Teamontwikkeling
                </Link>
              </li>
              <li>
                <Link to={"/opleidingen"} activeClassName={"active"}>
                  Opleidingen
                </Link>
              </li>
              <li>
                <Link to="/contact" activeClassName="active">
                  Contact
                </Link>
              </li>
              {/* <li>
                <Link to="/teamontwikkeling" activeClassName="active">
                  Teamontwikkeling
                </Link>
              </li>

              <li>
                <Link to="/organisatiestructuur" activeClassName="active">
                  Organisatie structureren
                </Link>
              </li> */}
              {/* <li>
                <Link to="/coaching" activeClassName="active">
                  Coaching
                </Link>
              </li> */}
              {/* <li>
                <Link to="/verzuimbegeleiding" activeClassName="active">
                  Verzuimbegeleiding
                </Link>
              </li> */}
              {/* <li>
                <Link to="/organisatieontwikkeling" activeClassName="active">
                  Organisatieontwikkeling
                </Link>
              </li> */}
              {/* <li>
                <Link to="/traject" activeClassName="active">
                  Traject
                </Link>
              </li> */}
              {/* <li>
                <Link to="/contact" activeClassName="active">
                  Contact
                </Link>
              </li> */}
            </ul>
            <div
              ref={hamBurgerIcon}
              className="navbar-toggle-btn"
              onClick={openMenu}
              style={{ padding: "50px" }}
            >
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </div>
          </div>
        </div>
      </nav>
      <ReactResizeDetector handleWidth onResize={onResize} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
